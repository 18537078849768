import { AlertOctagon } from "react-feather";
import { toast, Slide } from "react-toastify";
import { ToastContent } from "./ToastContent";
import i18next from "i18next";
// import { useTranslation } from "react-i18next"
// import { AbilityContext } from './utility/context/Can'

export const ErrorToast = (headline, description) => {
  return toast.error(
    <ToastContent
      headline={headline}
      description={description}
      color="danger"
      Icon={<AlertOctagon />}
    />,
    { icon: false, transition: Slide, hideProgressBar: true, autoClose: 5000 }
  );
};

const badRequestErrorHandler = (errors) => {
  if (Array.isArray(errors)) {
    errors.forEach((error) => {
      if (typeof error === "string") {
        ErrorToast(error);
      } else {
        badRequestErrorHandler(error);
      }
    });
  } else if (typeof errors === "object") {
    Object.keys(errors).forEach((key) => {
      if (Array.isArray(errors[key])) {
        errors[key].forEach((error) => {
          ErrorToast(`${key}: ${error}`);
        });
      } else if (typeof errors[key] === "string") {
        ErrorToast(`${key}: ${errors[key]}`);
      } else {
        badRequestErrorHandler(errors[key]);
      }
    });
  } else if (typeof errors === "string") {
    ErrorToast(errors);
  }

  // before 09-02-2023
  // if (typeof errors === 'object') {
  //     Object.keys(errors).forEach(key => {
  //         if (Array.isArray(errors[key])) {
  //             errors[key].forEach(error => {
  //                 ErrorToast(`${key}: ${error}`)
  //             })
  //         } else if (typeof errors[key] === 'string') {
  //             ErrorToast(`${key}: ${errors[key]}`)
  //         } else {
  //             badRequestErrorHandler(errors[key])
  //         }
  //     })
  // }
};

// const notAuthorizedErrMessage =
//   i18next.language === "en" ? "Not Authorized" : "مجاز نہیں";
// const forbiddenErrMessage = i18next.language === "en" ? "Forbidden" : "ممنوعہ";
// const notFoundErrMessage = i18next.language === "en" ? "Not found" : "نہیں ملا";
// const internalServerErrMessage =
//   i18next.language === "en" ? "Internal Server Error" : "اندرونی سرور کی خرابی";
// const wenWrongErrMessage =
//   i18next.language === "en"
//     ? "An error occurred. Please try again later."
//     : "ایک خرابی آگئی. براہ کرم کچھ دیر بعد کوشش کریں";

export const displayErrors = (error) => {
  // const { t } = useTranslation()

  if (error.response) {
    const { status } = error.response;

    if (status === 400) {
      if (error.response.data) {
        const errors_data = error.response.data;
        const errors =
          i18next.language === "en"
            ? errors_data?.error
            : errors_data?.error_ur;
        badRequestErrorHandler(errors);
      }
    } else if (status === 401) {
      const notAuthorizedErrMessage =
        i18next.language === "en" ? "Not Authorized" : "مجاز نہیں";
      ErrorToast(notAuthorizedErrMessage);
    } else if (status === 403) {
      const forbiddenErrMessage =
        i18next.language === "en" ? "Forbidden" : "ممنوعہ";
      ErrorToast(forbiddenErrMessage);
    } else if (status === 404) {
      const notFoundErrMessage =
        i18next.language === "en" ? "Not found" : "نہیں ملا";
      ErrorToast(notFoundErrMessage);
    } else if (status === 500) {
      const internalServerErrMessage =
        i18next.language === "en"
          ? "Internal Server Error"
          : "اندرونی سرور کی خرابی";
      ErrorToast(internalServerErrMessage);
    } else {
      const wenWrongErrMessage =
        i18next.language === "en"
          ? "An error occurred. Please try again later."
          : "ایک خرابی آگئی. براہ کرم کچھ دیر بعد کوشش کریں";
      ErrorToast(wenWrongErrMessage);
    }
  } else {
    const wenWrongErrMessage =
      i18next.language === "en"
        ? "An error occurred. Please try again later."
        : "ایک خرابی آگئی. براہ کرم کچھ دیر بعد کوشش کریں";
    ErrorToast(wenWrongErrMessage);
  }
};

// export const displayErrors = (error) => {
//   // const { t } = useTranslation()

//   if (error.response) {
//     const { status } = error.response
//     if (status === 400) {
//       if (error.response.data) {
//         const errors_data = error.response.data
//         console.clear()
//         const errors =
//           i18next.language === "en" ? errors_data?.error : errors_data?.error_ur
//         badRequestErrorHandler(errors)
//       }
//     } else if (status === 401) {
//       ErrorToast(notAuthorizedErrMessage)
//     } else if (status === 403) {
//       ErrorToast(forbiddenErrMessage)
//     } else if (status === 404) {
//       ErrorToast(notFoundErrMessage)
//     } else if (status === 500) {
//       ErrorToast(internalServerErrMessage)
//     } else {
//       ErrorToast(wenWrongErrMessage)
//     }
//   } else {
//     ErrorToast(wenWrongErrMessage)
//   }
// }
