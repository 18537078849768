import { AlertTriangle } from "react-feather"
import { toast, Slide } from 'react-toastify'
import { ToastContent } from "./ToastContent"

export const InfoToast = (headline, description) => {
  return toast.info(
    <ToastContent
      headline={headline}
      description={description}
      color="info"
      Icon={<AlertTriangle />}
    />,
    { icon: false, transition: Slide, hideProgressBar: true, autoClose: 5000 }
  )
}

