import { GET_ALL_DEVICES, GET_DEVICE, SEARCH_DEVICE } from "../constants"

// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: {},
  selectedDevice: null,
}

const plant_device = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_DEVICES:
      return { ...state, allData: action.allData }
    case SEARCH_DEVICE:
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params,
      }
    case GET_DEVICE:
      return { ...state, selectedDevice: action.selectedDevice }
    default:
      return { ...state }
  }
}
export default plant_device
