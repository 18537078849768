import { Check } from "react-feather"
import { toast, Slide } from 'react-toastify'
import { ToastContent } from "./ToastContent"
import i18next from "i18next"

export const SuccessToast = (headline, description) => {
  let headlineText = headline
  if (typeof headline === "object") {
    if (i18next.language === "en") {
      headlineText = headline.success
    } else {
      headlineText = headline.success_ur
    }
  }
  return toast.success(
    <ToastContent
      headline={headlineText}
      description={description}
      color="success"
      Icon={<Check />}
    />,
    { icon: false, transition: Slide, hideProgressBar: true, autoClose: 5000 }
  )
}
