import {
  edges as initialEdges,
  nodes as initialNodes,
} from "../../../../assets/data/flowInitialState"
import {
  ADD_PLANT,
  CURRENT,
  ENGERY_FLOW,
  DAILY_PRODUCTION,
  DELETE_PLANT,
  ENV_BENEFITS,
  GET_ALL_PLANTS,
  GET_PLANT,
  HISTORICAL_PRODUCTION,
  POWER_ANALYSIS,
  SEARCH_PLANT,
  UPDATE_PLANT,
  WEATHER_UPDATE,
  PHASE_DATA,
  GET_PLANT_TOPIC,
  DELETE_PLANT_PICTURE,
  DAILY_PRODUCTION_BY_ID,
  HISTORICAL_PRODUCTION_BY_ID,
  ENGERY_FLOW_BY_ID,
  INVERTER_PRODUCTION,
  GENERATOR_PRODUCTION,
  GRID_PRODUCTION,
  CONSUMPTION,
  INVERTER_PRODUCTION_BY_ID,
  GENERATOR_PRODUCTION_BY_ID,
  GRID_PRODUCTION_BY_ID,
  CONSUMPTION_BY_ID,
} from "../constants"

// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: {},
  selectedPlant: null,

  plantTopics: [],

  current: null,
  energyFlow: {
    nodes: initialNodes,
    edges: initialEdges,
  },
  energyFlowById: {
    nodes: initialNodes,
    edges: initialEdges,
  },
  // all plants
  inverterProduction: null,
  generatorProduction: null,
  gridProduction: null,
  consumption: null,
  // single plant
  inverterProductionById: null,
  generatorProductionById: null,
  gridProductionById: null,
  consumptionById: null,

  // productionOverviewById: null,
  // consumptionOverviewById: null,
  // gridOverviewById: null,
  // generatorOverviewById: null,

  dailyProduction: null,
  dailyProductionById: null,
  historicalProduction: null,
  historicalProductionById: null,

  weather: null,
  powerAnalysis: null,
  envBenefits: null,
}

const plants = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_PLANTS:
      return { ...state, allData: action.data }
    case SEARCH_PLANT:
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params,
      }
    case GET_PLANT:
      return { ...state, selectedPlant: action.selectedPlant }
    case UPDATE_PLANT:
      return { ...state, selectedPlant: action.selectedPlant }
    case ADD_PLANT:
      return { ...state }
    case DELETE_PLANT:
      return { ...state }
    case DELETE_PLANT_PICTURE:
      return { ...state }

    case GET_PLANT_TOPIC:
      return { ...state, plantTopics: action.plantTopics }

    case CURRENT:
      return { ...state, current: action.current }
    case PHASE_DATA:
      return { ...state, phaseData: action.phaseData }
    case ENGERY_FLOW:
      return { ...state, energyFlow: action.energyFlow }
    case ENGERY_FLOW_BY_ID:
      return { ...state, energyFlowById: action.energyFlowById }
    // case PRODUCTION_OVERVIEW:
    //   return {
    //     ...state,
    //     productionOverviewById: action.productionOverviewById,
    //   };
    // case CONSUMPTION_OVERVIEW:
    //   return {
    //     ...state,
    //     consumptionOverviewById: action.consumptionOverviewById,
    //   };
    // case GRID_OVERVIEW:
    //   return { ...state, gridOverviewById: action.gridOverviewById };
    // case GENRATOR_OVERVIEW:
    //   return { ...state, generatorOverviewById: action.generatorOverviewById };

    // all plants
    case INVERTER_PRODUCTION:
      return { ...state, inverterProduction: action.inverterProduction }
    case GENERATOR_PRODUCTION:
      return { ...state, generatorProduction: action.generatorProduction }
    case GRID_PRODUCTION:
      return { ...state, gridProduction: action.gridProduction }
    case CONSUMPTION:
      return {
        ...state,
        consumption: action.consumption,
      }
    // single plants
    case ENGERY_FLOW_BY_ID:
      return { ...state, energyFlow: action.energyFlow }
    case INVERTER_PRODUCTION_BY_ID:
      return {
        ...state,
        inverterProductionById: action.inverterProductionById,
      }
    case GENERATOR_PRODUCTION_BY_ID:
      return {
        ...state,
        generatorProductionById: action.generatorProductionById,
      }
    case GRID_PRODUCTION_BY_ID:
      return { ...state, gridProductionById: action.gridProductionById }
    case CONSUMPTION_BY_ID:
      return {
        ...state,
        consumptionById: action.consumptionById,
      }

    case DAILY_PRODUCTION:
      return { ...state, dailyProduction: action.dailyProduction }
    case DAILY_PRODUCTION_BY_ID:
      return { ...state, dailyProductionById: action.dailyProductionById }
    case HISTORICAL_PRODUCTION:
      return { ...state, historicalProduction: action.historicalProduction }
    case HISTORICAL_PRODUCTION_BY_ID:
      return {
        ...state,
        historicalProductionById: action.historicalProductionById,
      }

    case WEATHER_UPDATE:
      return { ...state, weather: action.weather }
    case ENV_BENEFITS:
      return { ...state, envBenefits: action.envBenefits }
    case POWER_ANALYSIS:
      return { ...state, powerAnalysis: action.powerAnalysis }
    default:
      return { ...state }
  }
}
export default plants
