import { ErrorToast, displayErrors } from "./ErrorToast"
import { InfoToast } from "./InfoToast"
import { SuccessToast } from "./SuccessToast"
import WarningToast from "./WarningToast"

export const success = SuccessToast
export const error = ErrorToast
export const info = InfoToast
export const warning = WarningToast

export default {
  success,
  error,
  info,
  warning,
  displayErrors
}
