import { ReactComponent as DayIcon } from "@src/assets/images/icons/day.svg"
import { ReactComponent as MonthIcon } from "@src/assets/images/icons/month.svg"
import { ReactComponent as YearIcon } from "@src/assets/images/icons/year.svg"
import { ReactComponent as TotalIcon } from "@src/assets/images/icons/total.svg"
export const HistoricalIcon = {
  Today: <DayIcon size={21} />,
  "This Month": <MonthIcon size={21} />,
  "This Year": <YearIcon size={21} />,
  Total: <TotalIcon size={21} />,
}

import { ReactComponent as CO2Icon } from "@src/assets/images/icons/CO2.svg"
import { ReactComponent as CoalIcon } from "@src/assets/images/icons/Coal.svg"
import { ReactComponent as TreeIcon } from "@src/assets/images/icons/tree.svg"
import { ReactComponent as YeildIcon } from "@src/assets/images/icons/yield.svg"
export const EnvIcons = {
  CarboxDioxide: CO2Icon,
  Coal: CoalIcon,
  Tree: TreeIcon,
  Yield: YeildIcon,
}

import { ReactComponent as PowerIcon } from "@src/assets/images/icons/power.svg"
import { ReactComponent as CurrentIcon } from "@src/assets/images/icons/current.svg"
import { ReactComponent as VoltageIcon } from "@src/assets/images/icons/voltage.svg"
export const DataIcon = {
  Power: PowerIcon,
  Current: CurrentIcon,
  Voltage: VoltageIcon,
}

import { ReactComponent as Grid } from "@src/assets/images/icons/grid.svg"
import { ReactComponent as Solar } from "@src/assets/images/icons/solar.svg"
import { ReactComponent as Generator } from "@src/assets/images/icons/generator.svg"
import { ReactComponent as Inverter } from "@src/assets/images/icons/inverter.svg"
import { ReactComponent as Consumption } from "@src/assets/images/icons/consumption.svg"
export const DeviceIcons = {
  Grid,
  Generator,
  Solar,
  Inverter,
  Consumption,
}

// export const DeviceIcons = {
//   Grid: Grid,
//   Generator: Generator,
//   Solar: Solar,
//   Inverter: Inverter,
//   Consumption: Consumption,
// }
