import FlowEdge from "../../views/plants/common/view/FlowEdge"
import FlowNode from "../../views/plants/common/view/FlowNode"
import { delay, kFormatter } from "@utils"

export const nodeProperties = {
  type: "custom",
  className: "text-primary",
  style: {
    borderColor: "#ffffff",
    border: "none",
    fontSize: "0.6rem",
  },
  draggable: false,
  selectable: false,
}

export const edgeProperties = {
  type: "smoothstep",
  // type: "simplebezier",
  // customEdge: FlowEdge,
  animated: false,
  style: { stroke: "#ffffff" },
}

export const nodes = [
  {
    id: "1",
    ...nodeProperties,
    position: { x: -50, y: -85 },
    data: {
      icon: "Solar",
      showLabel: false,
      label: "Solar",
      value: "0KW",
      sourcePosition: "bottom",
      targetPosition: "bottom",
    },
  },
  {
    id: "2",
    ...nodeProperties,
    position: { x: -50, y: 115 },
    data: {
      icon: "Generator",
      showLabel: false,
      label: "Generator",
      value: "0KW",
      sourcePosition: "bottom",
      targetPosition: "bottom",
    },
  },
  {
    id: "3",
    ...nodeProperties,
    position: { x: 150, y: 20 },
    data: {
      icon: "Inverter",
      showLabel: false,
      label: "Inverter",
      value: "0KW",
      // sourcePosition: "top",
      // targetPosition: "bottom",
    },
  },
  {
    id: "4",
    ...nodeProperties,
    position: { x: 360, y: -85 },
    data: {
      icon: "Grid",
      showLabel: false,
      label: "Grid",
      value: "0KW",
      sourcePosition: "top",
      targetPosition: "top",
    },
  },
  {
    id: "5",
    ...nodeProperties,
    position: { x: 360, y: 115 },
    data: {
      icon: "Consumption",
      showLabel: false,
      label: "Consumption",
      value: "0KW",
      sourcePosition: "top",
      targetPosition: "top",
    },
  },
]

export const edges = [
  {
    id: "e1-1",
    source: "1",
    target: "3",
    ...edgeProperties,
  },
  {
    id: "e1-2",
    source: "2",
    target: "3",
    ...edgeProperties,
  },
  {
    id: "e1-3",
    source: "3",
    target: "4",
    ...edgeProperties,
  },
  {
    id: "e1-4",
    source: "3",
    target: "5",
    ...edgeProperties,
  },
]
