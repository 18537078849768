import {
  ADD_USER,
  DELETE_USER,
  DELETE_USER_PICTURE,
  GET_ALL_USERS,
  GET_USER,
  SEARCH_USERS,
  UPDATE_USER,
} from "../constants"

// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: {},
  selectedUser: null,
}

const users = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_USERS:
      return { ...state, allData: action.data }
    case SEARCH_USERS:
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params,
      }
    case GET_USER:
      return { ...state, selectedUser: action.selectedUser }
    case UPDATE_USER:
      return { ...state, selectedUser: action.selectedUser }
    case ADD_USER:
      return { ...state }
    case DELETE_USER:
      return { ...state }
    case DELETE_USER_PICTURE:
      return { ...state }
    default:
      return { ...state }
  }
}
export default users
