// ** Core JWT Import
import { useJwt } from "@src/@core/auth/jwt/useJwt"

export const baseURL = "https://api.force-v.jinnahtech.com/api/"

const { jwt } = useJwt({
  loginEndpoint: `${baseURL}auth/login`,
  registerEndpoint: `${baseURL}auth/login`,
  refreshEndpoint: `${baseURL}auth/refresh-token`,
  logoutEndpoint: ` ${baseURL}auth/logout`,
  baseURL,
})

export default jwt
