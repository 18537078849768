export const GET_ALL_PLANTS = "GET_ALL_PLANTS"
export const SEARCH_PLANT = "SEARCH_PLANT"
export const GET_PLANT = "GET_PLANT"
export const UPDATE_PLANT = "UPDATE_PLANT"
export const ADD_PLANT = "ADD_PLANT"
export const DELETE_PLANT = "DELETE_PLANT"
export const DELETE_PLANT_PICTURE = "DELETE_PLANT_PICTURE"

export const CURRENT = "CURRENT"
export const PHASE_DATA = "PHASE_DATA"

// export const PRODUCTION_OVERVIEW = "PRODUCTION_OVERVIEW"
// export const CONSUMPTION_OVERVIEW = "CONSUMPTION_OVERVIEW"
// export const GRID_OVERVIEW = "GRID_OVERVIEW"
// export const GENRATOR_OVERVIEW = "GENRATOR_OVERVIEW"
// all plants
export const ENGERY_FLOW = "ENGERY_FLOW"
export const INVERTER_PRODUCTION = "INVERTER_PRODUCTION"
export const GENERATOR_PRODUCTION = "GENERATOR_PRODUCTION"
export const GRID_PRODUCTION = "GRID_PRODUCTION"
export const CONSUMPTION = "CONSUMPTION"
// single plant
export const ENGERY_FLOW_BY_ID = "ENGERY_FLOW_BY_ID"
export const INVERTER_PRODUCTION_BY_ID = "INVERTER_PRODUCTION_BY_ID"
export const GENERATOR_PRODUCTION_BY_ID = "GENERATOR_PRODUCTION_BY_ID"
export const GRID_PRODUCTION_BY_ID = "GRID_PRODUCTION_BY_ID"
export const CONSUMPTION_BY_ID = "CONSUMPTION_BY_ID"
// single and all plants production history
export const DAILY_PRODUCTION = "DAILY_PRODUCTION"
export const DAILY_PRODUCTION_BY_ID = "DAILY_PRODUCTION_BY_ID"
export const HISTORICAL_PRODUCTION = "HISTORICAL_PRODUCTION"
export const HISTORICAL_PRODUCTION_BY_ID = "HISTORICAL_PRODUCTION_BY_ID"

export const WEATHER_UPDATE = "WEATHER_UPDATE"
export const ENV_BENEFITS = "ENV_BENEFITS"
export const POWER_ANALYSIS = "POWER_ANALYSIS"

export const GET_PLANT_TOPIC = "GET_PLANT_TOPIC"
