import moment from "moment"
import themeConfig from "@configs/themeConfig"

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = (obj) => Object.keys(obj).length === 0

// ** Returns K format from a number
export const kFormatter = (num) =>
  num > 999 ? `${(num / 1000).toFixed(1)}k` : num

// ** Converts HTML to string
export const htmlToString = (html) => html.replace(/<\/?[^>]+(>|$)/g, "")

// ** Checks if the passed date is today
const isToday = (date) => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (
  value,
  formatting = { month: "short", day: "numeric", year: "numeric" }
) => {
  if (!value) return value
  return new Intl.DateTimeFormat("en-US", formatting).format(new Date(value))
}

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  let formatting = { month: "short", day: "numeric" }

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: "numeric", minute: "numeric" }
  }

  return new Intl.DateTimeFormat("en-US", formatting).format(new Date(value))
}

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => localStorage.getItem("userData")
export const getUserData = () => JSON.parse(localStorage.getItem("userData"))

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = (userRole) => {
  if (userRole === "admin") return "/"
  if (userRole === "client") return "/access-control"
  return "/login"
}

// ** React Select Theme Colors
export const selectThemeColors = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: "#7367f01a", // for option hover bg-color
    primary: "#7367f0", // for selected option bg-color
    neutral10: "#7367f0", // for tags bg-color
    neutral20: "#ededed", // for input border-color
    neutral30: "#ededed", // for input hover border-color
  },
})

// check if Val is object
export const isObject = (val) => {
  return val instanceof Object
}

// Years Month Calculator
export const getTenure = (startingDate) => {
  const tenure = moment(Date.now()).diff(moment(startingDate), "year", true)
  const tenureArray = tenure.toFixed(1).split(".")

  const tenureYrs = Number(tenureArray[0])
  const tenureMonths = Number(tenureArray[1])

  return tenureYrs
    ? `${tenureYrs} Yr ${tenureMonths} Mon`
    : `${tenureMonths} Mon`
}

export const boolToBit = (value) => {
  return value ? 1 : 0
}

export const roundOff = (number) => {
  if (isNaN(number)) return number

  if (Math.abs(number) % 1 !== 0) return number
  return Math.round(number)
}

export const dateFormatter = (date) => {
  if (!date) return
  return moment(date).format(themeConfig.defaults.dateFormat)
}

export const timestampFormatter = (timestamp) => {
  if (!timestamp) return
  return moment(timestamp).format(themeConfig.defaults.dateFormat)
}

export const dbDateFormatter = (dateToFormat) => {
  if (!dateToFormat) {
    return moment(Date.now()).format("YYYY-MM-DD")
  }
  if (moment(dateToFormat, "DD/MM/YYYY", true).isValid()) {
    return moment(dateToFormat, "DD/MM/YYYY").format("YYYY-MM-DD")
  }
  if (moment(dateToFormat[0]).isValid()) {
    return moment(dateToFormat[0]).format("YYYY-MM-DD")
  }
  return moment(Date.now()).format("YYYY-MM-DD")
}

export const getWatchedParamValue = (
  watch = () => {},
  key,
  isSelect = true
) => {
  if (isSelect) {
    if (watch(key)?.value === "custom") return null
    return watch(key)?.value || null
  }
  return watch(key)
}

export const defaultDateRange = [
  moment().format("DD-MM-YYYY"),
  moment().subtract(1, "year").format("DD-MM-YYYY"),
]

export const calcQtyFromSqft = (length, width, quantitySqft) => {
  return Number((quantitySqft * 144) / (length * width)).toFixed(2)
}

export const calcSqftFromQty = (length, width, quantity) => {
  return Number(((length * width) / 144) * quantity).toFixed(2)
}

export const convertToFloat = (valueToConvert) => {
  return Number(Number.parseFloat(valueToConvert).toFixed(2))
}

export const delay = 300000 // 5 minutes in milliseconds

/**
 * Capitalizes the first letter of a string.
 * @param {string} str - The input string.
 * @returns {string} The capitalized string.
 */
export const capitalizeFirstLetter = (str) => {
  if (typeof str !== "string") {
    return `${str}`
  }

  const words = str.split("_")
  const capitalizedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
  )
  return capitalizedWords.join(" ")
}

export const graphDateFormatter = (dateToFormat) => {
  if (!dateToFormat) {
    return moment().format("DD-MM-YYYY")
  }
  if (moment(dateToFormat, "DD/MM/YYYY", true).isValid()) {
    return moment(dateToFormat, "DD/MM/YYYY").format("DD-MM-YYYY")
  }
  if (moment(dateToFormat[0]).isValid()) {
    return moment(dateToFormat[0]).format("DD-MM-YYYY")
  }
  return moment().format("DD-MM-YYYY")
}

export const getXAxisLabel = (typeOfLabel) => {
  if (typeOfLabel === "daily") {
    return "Days"
  }

  if (typeOfLabel === "monthly") {
    return "Months"
  }

  if (typeOfLabel === "yearly") {
    return "Years"
  }

  return String(typeOfLabel)
}

export const getXAxisLabelById = (typeOfLabel) => {
  if (typeOfLabel === "last7days") {
    return "Day of Month"
  }

  if (typeOfLabel === "monthly") {
    return "Day of Month"
  }

  if (typeOfLabel === "yearly") {
    return "Months of Year"
  }

  return String(typeOfLabel)
}

export const getYAxisLabel = (typeOfLabel) => {
  return typeOfLabel || "kW"
}

export const verticalBarChartFormatter = (
  highlightColors = [],
  defaultDataset = {},
  dataset = {}
) => {
  return {
    labels: dataset?.labels,
    datasets:
      dataset?.datasets?.map((data, index) => ({
        ...defaultDataset,
        backgroundColor:
          data?.lineColor || highlightColors?.[index] || "#084688",
        // data: dataset.data,
        ...data,
      })) || [],
  }
}

export const defaultDateFormat = "YYYY-MM-DD"

const dateToday = moment()
export const defaultFilterRange = {
  startingDate: moment(dateToday).startOf("month").format(defaultDateFormat),
  endingDate: moment(dateToday).endOf("month").format(defaultDateFormat),
}

export const dateFilterDefaults = {
  start_date: moment().startOf("month").format(themeConfig.defaults.dateFormat),
  end_date: moment().endOf("month").format(themeConfig.defaults.dateFormat),
}
