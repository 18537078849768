import {
  GET_PLANTS_PRODUCTION,
  GET_PLANTS_STATUS,
  GET_PRODUCTION_SUMMARY,
} from "../constants"

// ** Initial State
const initialState = {
  // allData: [],
  // data: [],
  // total: 1,
  // params: {},
  // selectedPlant: null,
  plantsStatus: null,
  historicalProduction: null,
  productionSummary: null,
}

const dashboard = (state = initialState, action) => {
  switch (action.type) {
    case GET_PLANTS_STATUS:
      return { ...state, plantsStatus: action.plantsStatus }
    case GET_PLANTS_PRODUCTION:
      return { ...state, historicalProduction: action.historicalProduction }
    case GET_PRODUCTION_SUMMARY:
      return { ...state, productionSummary: action.productionSummary }
    default:
      return { ...state }
  }
}
export default dashboard
