// ** Reducers Imports
import navbar from "./navbar"
import layout from "./layout"
import auth from "./authentication"
// Account Reducers
// import accounts from "../views/accounts/account/store/reducer"
import dashboard from "../views/dashboard/store/reducer"
import users from "../views/accounts/users/store/reducer"
import role_permission from "../views/accounts/role-permissions/store/reducer"
import user_plants from "../views/accounts/users/view/store/reducer"
import plants from "../views/plants/store/reducer"
import faults from "../views/faults/store/reducer"
import plant_device from "../views/plants/view/device-grid/store/reducer"

const rootReducer = {
  auth,
  navbar,
  layout,
  // access control
  // accounts,
  users,
  role_permission,
  // plants
  dashboard,
  plants,
  faults,
  user_plants,
  plant_device,
}

export default rootReducer
