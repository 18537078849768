import { Fragment } from "react"
import Avatar from "@components/avatar"

export const ToastContent = ({ headline, description, color, Icon }) => {
  return (
    <Fragment>
      <div className="toastify-header">
        <div className="title-wrapper">
          <Avatar size="sm" color={color} icon={Icon} />
          <h6 className="toast-title fw-bold">{headline}</h6>
        </div>
      </div>
      <div className="toastify-body">
        <span>{description}</span>
      </div>
    </Fragment>
  )
}

