import {
  ADD_ROLE_PERMISSION,
  DELETE_ROLE_PERMISSION,
  GET_ALL_ROLE_PERMISSIONS,
  GET_ROLE_PERMISSION,
  SEARCH_ROLE_PERMISSION,
  UPDATE_ROLE_PERMISSION
} from "../constants"

// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: {},
  selRolePermission: null
}

const role_permission = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_ROLE_PERMISSIONS:
      return { ...state, allData: action.data }
    case SEARCH_ROLE_PERMISSION:
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case GET_ROLE_PERMISSION:
      return { ...state, selRolePermission: action.selRolePermission }
    case ADD_ROLE_PERMISSION:
      return { ...state }
    case UPDATE_ROLE_PERMISSION:
      return { ...state }
    case DELETE_ROLE_PERMISSION:
      return { ...state }
    default:
      return { ...state }
  }
}
export default role_permission
