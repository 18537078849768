import { GET_ALL_USER_PLANTS, SEARCH_USER_PLANTS } from "../constants"

// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: {},
  selectedPlant: null,
}

const user_plants = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_USER_PLANTS:
      return { ...state, allData: action.data }
    case SEARCH_USER_PLANTS:
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params,
      }
    // case UPDATE_USER_PLANT:
    //   return { ...state, selectedPlant: action.selectedPlant }
    // case DELETE_USER_PLANT:
    //   return { ...state }
    default:
      return { ...state }
  }
}
export default user_plants
