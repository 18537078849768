import { AlertTriangle } from "react-feather"
import { toast, Slide } from 'react-toastify'
import { ToastContent } from "./ToastContent"

export const WarningToast = (headline, description) => {
  return toast.warning(
    <ToastContent
      headline={headline}
      description={description}
      color="warning"
      Icon={<AlertTriangle />}
    />,
    { icon: false, transition: Slide, hideProgressBar: true, autoClose: 5000 }
  )
}

export default WarningToast
