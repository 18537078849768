import React from "react";
import { Card, CardBody } from "reactstrap";
import { Handle } from "react-flow-renderer";
import { DeviceIcons } from "@src/components/reusables/Icons";

const Icon = ({ name, ...rest }) => {
  const IconComponent = DeviceIcons[name];

  if (!IconComponent) {
    return null; // or render a default icon or a placeholder
  }

  return <IconComponent {...rest} />;
};

const handleStyles = {
  visibility: "hidden",
  // background: "#084688",
};

const FlowNode = ({ data }) => {
  const shadowClass =
    data?.icon === "Inverter" ? "shadow-none" : "shadow rounded";
  // const cardSyles =
  //   data?.icon === "Inverter" ? { backgroundColor: "inherit" } : {}

  return (
    <Card
      className={`${shadowClass} text-light bg-primary p-0`}
      //  style={cardSyles}
    >
      <Handle
        style={handleStyles}
        type="target"
        position={data?.targetPosition || "top"}
      />
      <CardBody
        className="d-flex flex-column align-items-center justify-content-around pt-25 p-25"
        // style={{ backgroundColor: "yellow", overflow: "hidden", zIndex: 9000 }}
      >
        <div className="px-50">
          <small
            className={data?.value ? "visible" : "invisible"}
            style={{ fontSize: ".70rem" }}
          >
            {data?.value || ""}
          </small>
        </div>
        <div
          className={`avatar p-50 ${
            data?.value ? "" : "p-1"
          } bg-light cardStyles`}
          style={{ zIndex: "2" }}
        >
          <div
            className="avatar-content"
            style={{ width: "30px", height: "30px" }}
          >
            <Icon name={data?.icon} />
          </div>
        </div>
        <div className="px-50">
          {data.showLabel ? (
            <small className="" style={{ fontSize: ".70rem" }}>
              {data?.label}
            </small>
          ) : null}
        </div>
      </CardBody>
      <Handle
        style={handleStyles}
        type="source"
        position={data?.sourcePosition || "bottom"}
      />
    </Card>
  );
};

export default FlowNode;
