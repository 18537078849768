import {
  ADD_FAULT,
  DELETE_FAULT,
  GET_ALL_FAULTS,
  GET_FAULT,
  SEARCH_FAULT,
  UPDATE_FAULT,
} from "../constants"

// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: {},
  selectedFault: null,
}

const faults = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_FAULTS:
      return { ...state, allData: action.data }
    case SEARCH_FAULT:
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params,
      }
    case GET_FAULT:
      return { ...state, selectedFault: action.selectedFault }
    case UPDATE_FAULT:
      return { ...state, selectedFault: action.selectedFault }
    case ADD_FAULT:
      return { ...state }
    case DELETE_FAULT:
      return { ...state }
    default:
      return { ...state }
  }
}
export default faults
